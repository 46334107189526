import React from "react";

export const Footer: React.FC<{}> = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content has-text-centered">
          <p>
            <strong>SGFT Ltd</strong> is a company registered in England and
            Wales
          </p>
          <br />
          <p>Company Number: 09630717</p>
          <p>VAT Number: GB222026659</p>
          <p>
            Email: <a href="mailto:info@sg-ft.com">info@sg-ft.com</a>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
};
