import React from "react";
import { ReactComponent as Logo } from "../images/sgft-logo.svg";

export const Header: React.FC<{}> = () => {
  return (
    <div className="header">
      <div className="container p-1">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="#/">
              <Logo width="112" height="28" />
            </a>

            <a
              role="button"
              className="navbar-burger"
              aria-label="menu"
              aria-expanded="false"
              href="/"
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div id="navbarBasicExample" className="navbar-menu">
            <div className="navbar-start">
              <a className="navbar-item" href="/">
                Home
              </a>
            </div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <a className="button is-light" href="/#">
                  Log in
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
