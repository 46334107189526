import React from "react";
import "./App.scss";
import { ReactComponent as PrimeLogo } from "./images/prime-logo.svg";
import { Footer } from "./nav/Footer";
import { Header } from "./nav/Header";

function App() {
  return (
    <div>
      <Header />
      <section className="hero is-link is-medium">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">SGFT</h1>
            <h2 className="subtitle">
              Technology solutions for financial service organizations
            </h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container mt-6 mb-6">
          <h1 className="title">Products</h1>
          <PrimeLogo style={{ width: 100 }} className="prime-logo" />
          <br />
          PRIME - Product Control Workflow and Reporting
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default App;
